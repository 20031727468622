.leaflet-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 55px);
}

/* .leaflet-top,
.leaflet-left,
.leaflet-control { */
/* margin-top: 5rem; */
/* } */

.sr-only {
    display: none;
}

.map-container {
    position: relative;
}

.fab-container {
    position: absolute; /* Required for bottom and left to work */
    bottom: 20px;
    right: 20px;
    z-index: 1000; /* Ensures it appears above the map */
}

.dialog-container .MuiDialog-paper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: auto; /* Optional: Adjust width if needed */
    max-width: 400px; /* Optional: Max width */
}