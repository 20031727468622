.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, #0A1B3A, #37465E);
    height: 55px;
}

.navbar_not_authorized {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #0A1B3A, #37465E);
    height: 55px;
}

.navbar_logout_icon {
    margin-left: 10px;
}

.navbar_settings_icon {
    margin-right: 10px;
}

.navbar_image {
    height: 55px;
}